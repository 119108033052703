<template>
  <div class="page admin-appointments-id">
    <!-- Bannière en top -->
    <vs-breadcrumb
      :items="[
        {
          title: 'Admin',
          disabled: true
        },
        {
          title: 'rendez-vous',
          url: '/admin/rendez-vous'
        },
        {
          title: rendezVous && rendezVous.id,
          active: true
        },
      ]"
    />

    <!-- Rendez-vous -->
    <div class="mt-5 negociateur">
      <vx-card title="Détails du rendez-vous">
        <div class="associated-info">
          <!-- L'agent concerné -->
          <vs-card v-if="agent">
            <div slot="header">
              <h3>Négociateur</h3>
            </div>
            <div class="mb-6 flex flex-wrap" >
              <img
                alt=""
                class="agent-image"
                :src="`https://images.proprietes-privees.com/negociateur/${agent.trigramme}.png`"
              >
              <vs-list class="flex-1 agent-details">
                <vs-list-item title="Prenom" :subtitle="agent.firstName" />
                <vs-list-item title="Nom" :subtitle="agent.lastName" />
                <vs-list-item title="Email" :subtitle="agent.email" />
                <vs-list-item title="Téléphone" :subtitle="agent.telephone" />
              </vs-list>
            </div>
            <div slot="footer">
              <vs-row vs-justify="flex-end">
                <vs-button
                  v-if="!rendezVous || ['A venir', 'A statuer'].includes(rendezVous.statut)"
                  class="mr-1"
                  color="warning"
                  icon="switch_account"
                  type="gradient"
                  @click="() => searchAgent.showForm = true"
                >
                  <span class="hidden sm:inline">Changer le négociateur</span>
                </vs-button>
                <vs-button
                  color="primary"
                  icon="event"
                  type="gradient"
                  :to="{ name: 'admin-diary', query: { idNego: agent.id } }"
                >
                  <span class="hidden sm:inline">Agenda</span>
                </vs-button>
              </vs-row>
            </div>
          </vs-card>

          <vs-card v-else>
            <vs-button
              class="mr-1"
              color="warning"
              icon="switch_account"
              type="gradient"
              @click="() => searchAgent.showForm = true"
            >
              <span class="hidden sm:inline">Ajouter négociateur</span>
            </vs-button>
          </vs-card>

          <!-- L'annonce concernée -->
          <vs-card v-if="rendezVous && rendezVous.ad" class="announcement">
            <div slot="header">
              <h3>
                Annonce
                {{ rendezVous.ad.tradeType.name }} -
                {{ rendezVous.ad.price | priceFormat }}
              </h3>
            </div>

            <!-- Contenu de la carte -->
            <div>
              <!-- Info utile de l'annonce -->
              <div class="announcement-details">
                <img class="announcement-image" :src="rendezVous.ad.photo" alt="">
                <div>
                  <div class="break-float">
                    <vs-chip color="warning">{{ rendezVous.ad.tradeType.name }}</vs-chip>
                    <vs-chip color="warning">
                      <strong>Réf : </strong> {{rendezVous.ad.reference}}
                    </vs-chip>
                    <vs-chip color="warning">{{rendezVous.ad.surface}} m<sup>2</sup></vs-chip>
                  </div>
                  <vs-list>
                    <vs-list-item title="Nom du vendeur" :subtitle="rendezVous.ad.nomVendeur" />
                    <vs-list-item
                      title="Téléphone du vendeur"
                      :subtitle="rendezVous.ad.mobileNumber"
                    />
                    <vs-list-item
                      title="Adresse du bien"
                      :subtitle="`
                        ${rendezVous.ad.adresseBien},
                        ${rendezVous.ad.postalCode} - ${rendezVous.ad.adCity}`
                      "
                    />
                  </vs-list>
                </div>
              </div>

              <!-- Description de l'annonce -->
              <vs-collapse>
                <vs-collapse-item class="collapse">
                  <div slot="header">Description de l'annonce</div>
                  <p>{{rendezVous.ad.description}}</p>
                </vs-collapse-item>
              </vs-collapse>
            </div>
            <div slot="footer" v-if="rendezVous.ad.adUrl">
              <vs-row vs-justify="flex-end">
                <vs-button
                  color="warning"
                  icon="open_in_new"
                  type="gradient"
                  :href="rendezVous.ad.adUrl"
                >
                  Lien de l'annonce
                </vs-button>
              </vs-row>
            </div>
          </vs-card>
        </div>

        <!--  -->
        <div v-if="rendezVous" style="border: none">

          <!--  -->
          <p class="idAd">
            Rendez vous ayant l'identifiant
            <span>{{rendezVous.id}}</span>
            portant sur la commande
            <span v-if="rendezVous.commande">
              <router-link :to="{ name: 'admin-orders-id', params: { id: rendezVous.commande.id }}">
                {{rendezVous.commande.reference}}
              </router-link>
            </span>
          </p>

          <!-- Détails du rendez-vous -->
          <vs-row class="corpsRDV mt-6">
            <!-- Statut, Date et Type -->
            <vs-col vs-justify="center" vs-align="center" vs-w="3">
              <span class="statut">
                <feather-icon svgClasses="w-4 h-4" icon="ApertureIcon" class="mr-2"/>
                <b>Statut : </b>
                <div class="withOverlay">
                  <feather-icon
                    v-if=" this.oldStatut == 'En attente d\'annulation'"
                    svgClasses="w-4 h-4"
                    :icon="iconAnnulation"
                    class="mr-2 "
                  />
                  <div class="alt-overlay">
                    <div class="text">{{ labelAnnulation }}</div>
                  </div>
                </div>

                <v-select
                  :options="optionsStatut"
                  placeholder="Statut"
                  v-model="rendezVous.statut"
                />
              </span>
              <br>
              <feather-icon svgClasses="w-4 h-4" icon="CalendarIcon" class="mr-2 " />
              <b>Date : </b>{{ rendezVous.dateDebut | formDate }}
              <br>
              <feather-icon svgClasses="w-4 h-4" icon="TagIcon" class="mr-2 " />
              <b>Type : </b>{{ rendezVous.type }}
            </vs-col>

            <!-- Commentaire -->
            <vs-col vs-justify="center" vs-align="center" vs-w="6">
                <textarea
                  type="text"
                  v-model="rendezVous.commentaire"
                  class="w-full textarea"
                  placeholder="Notes précisions"
                >Notes précisions </textarea>
            </vs-col>

            <!-- Mandat -->
            <vs-col vs-justify="center" vs-align="center" vs-w="3">
              <feather-icon svgClasses="w-4 h-4" icon="FileTextIcon" class="mr-2"/>
              <b>Numéro du mandat :</b>
              <vs-input
                class="w-full mb-2"
                type="text"
                :disabled="rendezVous.statut !== 'Réalisé'"
                @change="validerLongeurMandat"
                v-model="rendezVous.mandat"
              />
              <vs-button
                v-if="rendezVous.mandat"
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-search"
                @click="getMandat()"
              >
                Détails mandat
              </vs-button>
            </vs-col>
          </vs-row>

          <!-- Téléconseiller -->
          <vs-row class="mt-20">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <div v-if="rendezVous.teleconseiller">
                <b>Téleconseiller : </b>
                {{rendezVous.teleconseiller.firstName}} {{rendezVous.teleconseiller.lastName}}<br>
                Dernière mise à jour <b>: {{dateMiseAjour}} </b>Par <b>{{ whoUpdated}}</b>
              </div>
            </vs-col>
          </vs-row>

          <!-- Alertes -->
          <vs-row
            v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Réalisé'"
            class="mt-5"
          >
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-alert color="success" title="Alerte" active="true">
                Si le négociateur a eu un mandat, n'oubliez pas de mettre son numéro.
              </vs-alert>
            </vs-col>
          </vs-row>
          <vs-row
            v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Annulé'"
            class="mt-5 mb-10"
          >
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-alert color="warning" title="Alerte" active="true">
                Veuillez mettre à jour la fiche de la commande.<br>
                La mise à jour automatique se fait suite à une demande d'annulation.
              </vs-alert>
            </vs-col>
          </vs-row>

          <!-- Bouton de validation -->
          <div class="mt-6 float-right">
            <vs-button @click="editEvent()">Modifier</vs-button>
          </div>
        </div>
        <br>
      </vx-card>

      <!-- Historique des actions -->
      <div class="vx-col w-full mb-base mt-20">
        <vx-card title="Historique" @refresh="closeCardAnimationDemo" action-buttons>
          <vx-timeline :data="timelineData" />
        </vx-card>
      </div>
    </div>

    <!-- Popup d'affichage des détails d'un mandats -->
    <vs-popup
      :active.sync="popup"
      title="Détails du mandat"
    >
      <div v-if="mandatDetails !== null">
        <b>Réference : </b> {{mandatDetails.reference}}<br>
        <b>Date de début : </b> {{mandatDetails.startDate.date}}<br>
        <b>Prix de vente : </b> {{mandatDetails.salePrice | priceFormat}}<br>
        <b>Prix hors honoraire : </b> {{mandatDetails.prixHorsHonoraires | priceFormat}}<br>
        <b>Statut : </b> {{mandatDetails.tradeStatus.name}}<br>
        <b>Type : </b> {{mandatDetails.tradeType.name}}<br>
        <b>Négociateur : </b>
        {{mandatDetails.mandatary.firstName}} {{mandatDetails.mandatary.lastName}}<br>
      </div>
      <div v-else>
        Numéro de mandat introuvable
      </div>
    </vs-popup>

    <!-- Popup Formulaire de changement de négociateur -->
    <vs-popup
      button-close-hidden
      title="Changement du négociateur"
      :active.sync="searchAgent.showForm"
    >
      <template v-if="agent">
        <!-- Détail Négociateur actuel -->
        <div>
          <vs-list>
            <vs-list-item title="Négociateur" :subtitle="fullName.toString()" />
            <vs-list-item title="Commande" :subtitle="orderReference.toString()" />
          </vs-list>
        </div>

        <!-- Séparateur -->
        <p class="my-5 text-center uppercase">
          ▼ Sera modifié par ▼
        </p>
      </template>

      <!-- Formulaire d'ajout d'un agent -->
      <template v-else>
        <vs-row class="mb-5" vs-justify="space-around">
          <!-- Date de début du Rendez-vous -->
          <vs-col vs-w="6">
            <label for="startDate">Date de début de RDV</label>
            <datepicker id="startDate" v-model="newEventForm.startDate" />
          </vs-col>
          <!-- Duration du rendez-vous -->
          <vs-col vs-w="5" vs-offset="1" vs-justify="flex-end" vs-type="flex">
            <vs-input
              v-model="newEventForm.duration"
              type="number"
              label="Durée du rendez-vous"
            />
          </vs-col>
        </vs-row>

        <!-- Description du Rendez-vous -->
        <vs-textarea class="my-5" label="Description du rendez-vous" v-model="newEventForm.desc" />
      </template>

      <!-- Sélection du négociateur -->
      <v-select
        v-model="searchAgent.value"
        class="mb-3"
        label="fistName"
        placeholder="Recherche par nom et sélection du négociateur"
        :filterable="false"
        :options="searchAgent.options"
        @option:selected="handleSelectAgent"
        @search="handleSearchAgent"
      >
        <div class="spinner" v-show="searchAgent.loading">Loading...</div>

        <!-- Templating des options -->
        <template #option="{ firstName, lastName, trigramme }">
          <div class="agents-option">
            <img
              alt=""
              class="agents-option-avatar"
              :src="`https://images.proprietes-privees.com/negociateur/${trigramme}.png`"
            >
            <strong>{{ firstName }} {{ lastName }}</strong>
          </div>
        </template>

        <!-- Templating de l'option séléctionné -->
        <template #selected-option="{ firstName, lastName, trigramme }">
          <div class="agents-option">
            <img
              alt=""
              class="agents-option-avatar"
              :src="`https://images.proprietes-privees.com/negociateur/${trigramme}.png`"
            >
            <strong>{{ firstName }} {{ lastName }}</strong>
          </div>
        </template>
      </v-select>

      <!-- Sélection de la commande du négociateur -->
      <v-select
        v-if="searchAgent.value && orders.options.length && !orders.loading"
        v-model="orders.value"
        label="reference"
        placeholder="Selection de la commande à associer"
        :disabled="!orders.options.length"
        :options="orders.options"
      >
        <!-- Templating des options -->
        <template #option="{ reference, nbRdvRestants }">
          <div class="orders-option">
            <strong>{{ reference }}</strong>
            <span class="remaining-appointment">Rendez-vous restants: {{ nbRdvRestants }}</span>
          </div>
        </template>
      </v-select>

      <!-- Message dans le cas où il n'y a aucune commande disponible -->
      <vs-alert
        :active="searchAgent.value && !orders.options.length && !orders.loading"
        color="danger"
      >
        Ce négociateur n'a pas de commandes actives
      </vs-alert>

      <!-- Bouttons d'action -->
      <vs-row vs-justify="end" class="mt-5">
        <vs-button
          class="mr-2"
          color="danger"
          type="gradient"
          @click="resetSearchAgent"
        >
          Annuler
        </vs-button>
        <vs-button
          class="vs-con-loading__container"
          color="success"
          id="search-agent-button"
          ref="searchAgentButton"
          type="gradient"
          :disabled="!searchAgent.value || !orders.value"
          @click="agent ? handleChangeAgent() : handleAddAgent()"
        >
          Valider
        </vs-button>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import VSelect from 'vue-select';
import { addMinutes, format } from 'date-fns';
import imageRdv from '@/assets/images/pages/historique.png';
import VxTimeline from '@/components/timeline/VxTimeline.vue';
import debounce from '@/mixins/debounce';

export default {
  name: 'admin-appointments-id',
  components: {
    VSelect,
    Datepicker,
    VxTimeline,
  },
  mixins: [debounce],
  props: ['id'],
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }

      return '';
    },
    priceFormat(value) {
      if (value) {
        const val = (value / 1).toFixed(0).replace('.', ' ');
        return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} € `;
      }

      return '';
    },
  },
  data() {
    return {
      agent: null,
      agentChangePopup: false,
      breadcrumbs: [],
      searchAgent: {
        showForm: false,
        value: null,
        options: [],
        orders: [],
      },
      orders: {
        value: null,
        options: [],
      },
      newEventForm: {
        startDate: new Date(),
        duration: 30,
        desc: '',
      },
      popup: false,
      timelineData: [],
      secteur: [],
      enAttente: '',
      Suspendus: '',
      enCours: '',
      Clotures: '',
      commandes: '',
      titreCommande: '',
      rendezVous: null,
      imageRdv,
      nomVendeur: '',
      motifAnnulation: '',
      confirmation: false,
      commentaireAnnulation: '',
      iconAnnulation: '',
      labelAnnulation: '',
      dateMiseAjour: '',
      whoUpdated: '',
      optionsStatut: ['A venir', 'A statuer', 'Réalisé', 'Annulé', 'En attente d\'annulation'],
      optionsMotif: ['Bien vendu', 'Ne souhaite plus vendre', "N'a jamais eu RDV", 'Non présent', 'Mauvais coordonnées', 'Autre'],
      today: new Date(),
      oldStatut: '',
      mandatDetails: null,
    };
  },
  watch: {
    'searchAgent.value': function (value) {
      if (!value) {
        this.resetOrders();
      }
    },
  },
  computed: {
    fullName() {
      if (this.agent) {
        return `${this.agent.firstName} ${this.agent.lastName}`;
      }
      return '';
    },
    orderReference() {
      if (this.rendezVous && this.rendezVous.commande) {
        return this.rendezVous.commande.reference;
      }

      return 'Ce rendez-vous n\'a pas de commande attribué';
    },
  },
  methods: {
    validerLongeurMandat() {
      if (this.rendezVous.mandat.length !== 6) {
        this.rendezVous.mandat = '';
        this.$toasted.show('Le numéro de mandat doit contenir 6 caractéres!').goAway(1500);
      }
    },
    async getMandat() {
      this.popup = true;
      const numMandat = this.rendezVous.mandat;
      const { trigramme } = this.rendezVous.negociateur;
      const { data } = await this.$http.get(`/api/mandat/${numMandat + trigramme}`);
      this.mandatDetails = data.length ? data[0] : null;
    },
    closeCardAnimationDemo(card) {
      card.removeRefreshAnimation(3000);
    },
    validFormAnnulation() {
      return this.motifAnnulation !== '' && this.confirmation !== '';
    },
    editEvent() {
      const obj = {
        user: this.$store.state.AppActiveUser.user.id,
        id: this.id,
        statut: this.rendezVous.statut,
        commentaire: this.rendezVous.commentaire,
        mandat: this.rendezVous.mandat,
        idRdv: this.rendezVous.id,
      };
      if (this.rendezVous.statut === 'Annulé') {
        obj.dateAnnulation = moment().format('MM/DD/YYYY  h:mm:ss a');
      }
      this.$http.patch(`/api/rendez_vouses/${this.id}`, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-type': 'application/merge-patch+json',
        },
      }).then(() => {
        const objAD = {
          nomVendeur: this.rendezVous.ad.nomVendeur,
          adresseBien: this.rendezVous.ad.adresseBien,
        };
        this.$http.put(`/api/ads/${this.rendezVous.ad.id}`, objAD, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then(() => { this.getHistorique(this.id); });
      });

      this.nomVendeur = '';

      this.confirmation = false;
      this.$vs.notify({
        title: 'Succès',
        text: 'Le rendez-vous a été mis à jour!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 5000,
      });
    },
    checkAnnulation(rdvId) {
      this.$http.get(`/api/demande_annulations?rendezVous=${rdvId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          if (response.data.length > 0) {
            if (response.data[0].statut === 'A traiter') {
              this.iconAnnulation = 'AlertCircleIcon';
              this.labelAnnulation = 'Demande en cours de traitement';
            } else if (response.data[0].statut === 'Refusé') {
              this.iconAnnulation = 'XOctagonIcon';
              this.labelAnnulation = 'Demande refusée, pas de remboursement';
            } else {
              this.iconAnnulation = 'CheckCircleIcon';
              this.labelAnnulation = 'Demande acceptée';
            }
          }
        });
    },
    getHistorique(id) {
      const colors = ['primary', 'warning', 'danger', 'success'];
      this.$http.get(`/api/historique_rdvs?rendezVous=${id}&order[dateAction]=desc`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((responseRdv) => {
          let oneLine = {};
          const timeline = [];
          const { data } = responseRdv;
          let i = (responseRdv.data).length;
          data.forEach((value) => {
            oneLine = {
              color: colors[Math.floor(Math.random() * colors.length)],
              icon: value.libelle === 'Creation' ? 'PlusIcon' : 'EditIcon',
              title: value.libelle,
              desc: `${value.text}\n \nPar ${value.who}`,
              time: value.dateAction,
            };
            if (value.libelle === 'Mise à jour') {
              oneLine.title = `${value.libelle} ${i}`;
              i -= 1;
            }
            timeline.push(oneLine);
          });
          this.timelineData = timeline;
          if (responseRdv.data.length !== 0) {
            this.whoUpdated = responseRdv.data[0].who;
            this.dateMiseAjour = moment(responseRdv.data[0].dateAction).format('YYYY-MM-DD HH:mm:ss ');
          } else {
            this.whoUpdated = '';
            this.dateMiseAjour = '';
          }
        });
    },

    /**
     * Evenement déclenché lors de la saisie de text dans un champ.
     * Récupération des premier négociateur selon le nom.
     * Affichage des données dans un select.
     */
    handleSearchAgent(event, loading) {
      const searchText = event;
      this.debounce(async () => {
        loading(true);
        const url = this.$urlBuilder('/api/mandataries')
          .appendQuery('lastName', searchText)
          .appendQuery('pagination', true)
          .appendQuery('itemsPerPage', 5);

        const { data: agents } = await this.$http.get(url.format());
        this.searchAgent.options = agents;
        loading(false);
      });
    },

    /**
     * Evenement Déclenché lors de la selection d'un item dans un select.
     * Récupère toute les commandes disponible pour l'agent selectionné.
     * Affichage dans un select.
     * @param {Object} agent - Objet de l'agent séléctionné
     */
    async handleSelectAgent(agent) {
      this.orders.loading = true;
      const url = this.$urlBuilder('/api/commandes')
        .appendQuery('trigramme', agent.trigramme)
        .appendQuery('statut', 'EC');

      const { data: orders } = await this.$http.get(url.format());
      this.orders.options = orders;
      this.orders.loading = false;
    },

    /**
     * Evenement déclenché lors de la soumission du formulaire de changement d'agent.
     * 1. Créer un nouvel evenement chez le nouvel agent.
     * 2. Supprime l'evenement chez l'acien agent.
     * 3. Modifie l'appartenance et la commande du rendez-vous.
     */
    async handleChangeAgent() {
      // Id example : 9573
      if (!this.searchAgent.value) {
        this.$vs.notify({
          title: 'Erreur',
          text: 'Aucun nouveau négociateur selectionné',
          color: 'danger',
        });
        return;
      }

      const newUser = this.searchAgent.value;
      const newEventUserId = newUser.id;
      const oldUser = this.rendezVous.negociateur;
      const oldEventUserId = oldUser.id;
      const eventId = this.rendezVous.evenement;
      const appointmentId = this.rendezVous.id;

      if (eventId) {
        try {
          this.$vs.loading({
            color: '#999',
            container: '#search-agent-button',
            scale: 0.5,
          });

          // Récupération des données depuis l'agenda Zimbra.
          const eventUrl = this.$urlBuilder('/api/evenement/getAppointment/:userId/:appointmentId')
            .appendParams(':userId', oldEventUserId)
            .appendParams(':appointmentId', eventId);
          const { data: event } = await this.$http.get(eventUrl.format());

          // Creation de l'event sur l'agenda du nouvel utilisateur.
          const { data: newEventId } = await this.$http.post(`/api/evenement/addAppointment/${newEventUserId}`, {
            description: event.desc,
            end: event.end,
            invId: event.invId,
            start: event.start,
            title: event.title,
            user: newEventUserId,
          });

          // Suppression de l'ancien evenement.
          await this.$http.post(`/api/evenement/deleteAppointment/${oldEventUserId}`, {
            invId: event.invId,
            id: event.id,
            user: oldUser.id,
          });

          // Modification du rendez-vous.
          const { data: updatedOrder } = await this.$http.patch(`/api/rendez_vouses/${appointmentId}`, {
            evenement: newEventId,
            negociateur: `/api/mandataries/${newUser.id}`,
            commande: `/api/commandes/${this.orders.value.id}`,
          }, {
            headers: {
              'Content-Type': 'application/merge-patch+json',
            },
          });

          // Mise à jour du rendez-vous dans le state.
          this.$set(this, 'rendezVous', updatedOrder);
          this.$set(this, 'agent', newUser);

          // Fermeture de la popup avec reset des champ.
          this.resetSearchAgent();
          this.searchAgent.showForm = false;
        } catch (error) {
          this.$vs.notify({
            title: 'Erreur',
            text: this.$parseError(error).message,
            color: 'danger',
          });
        } finally {
          this.$vs.loading.close('#search-agent-button > .con-vs-loading');
        }
      }
    },

    /**
     * Evenement déclenché lors de la soumission du formulaire d'ajout d'un agent.
     * 1. Créer un nouvel evenement chez le nouvel agent.
     * 3. Modifie l'appartenance et la commande du rendez-vous.
     */
    async handleAddAgent() {
      if (!this.searchAgent.value) {
        this.$vs.notify({
          title: 'Erreur',
          text: 'Aucun nouveau négociateur selectionné',
          color: 'danger',
        });
        return;
      }

      const newUser = this.searchAgent.value;
      const newEventUserId = newUser.id;
      const eventId = this.rendezVous.evenement;
      const appointmentId = this.rendezVous.id;

      if (eventId) {
        try {
          this.$vs.loading({
            color: '#999',
            container: '#search-agent-button',
            scale: 0.5,
          });

          // Creation de l'event sur l'agenda du nouvel utilisateur.
          const { data: newEventId } = await this.$http.post(`/api/evenement/addAppointment/${newEventUserId}`, {
            description: this.newEventForm.desc,
            start: format(new Date(this.newEventForm.startDate), 'yyyy/MM/dd HH:mm'),
            end: format(addMinutes(new Date(this.newEventForm.startDate), this.newEventForm.duration), 'yyyy/MM/dd HH:mm'),
            title: `RDV Avec ${this.rendezVous.ad.nomVendeur}`,
            user: newEventUserId,
          });

          // Modification du rendez-vous.
          const { data: updatedOrder } = await this.$http.patch(`/api/rendez_vouses/${appointmentId}`, {
            evenement: newEventId,
            negociateur: `/api/mandataries/${newUser.id}`,
            commande: `/api/commandes/${this.orders.value.id}`,
          }, {
            headers: {
              'Content-Type': 'application/merge-patch+json',
            },
          });

          // Mise à jour du rendez-vous dans le state.
          this.$set(this, 'rendezVous', updatedOrder);
          this.$set(this, 'agent', newUser);

          // Fermeture de la popup avec reset des champ.
          this.resetSearchAgent();
          this.searchAgent.showForm = false;
        } catch (error) {
          this.$vs.notify({
            title: 'Erreur',
            text: this.$parseError(error).message,
            color: 'danger',
          });
        } finally {
          this.$vs.loading.close('#search-agent-button > .con-vs-loading');
        }
      }
    },

    /**
     * Remise à zero du formulaire de changement d'agent.
     */
    resetSearchAgent() {
      this.searchAgent.value = null;
      this.searchAgent.showForm = false;
      this.searchAgent.options = [];
      this.resetOrders();
    },
    /**
     * Remise à zero de la selection des commandes.
     */
    resetOrders() {
      this.orders.options = [];
      this.orders.value = null;
    },
  },
  created() {
    this.$http.get(`/api/rendez_vouses/${this.id}`)
      .then((response) => {
        this.rendezVous = response.data;
        this.agent = response.data.negociateur;
        this.checkAnnulation(response.data.id);
        this.oldStatut = response.data.statut;
        this.getHistorique(response.data.id);
      })
      .catch((error) => {
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          color: 'danger',
        });
      });
  },
  beforeDestroy() {
    this.resetSearchAgent();
  },
};
</script>
<style lang="scss">
.search-agent-loading {
  height: 100px;
  margin: auto;
  width: 100px;
}
.vs-popup--content {
  overflow: visible;
}
.agents-option {
  display: flex;
  align-items: center;

  &-avatar {
    border-radius: 30px;
    display: block;
    height: 30px;
    margin-right: 3px;
    object-fit: cover;
    object-position: top;
    width: 30px;
  }
}

.orders-option {
  display: flex;
  justify-content: space-between;

  .remaining-appointment {
    font-style: italic;
  }
}

.admin-appointments-id {
  .associated-info {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    align-items:start;
  }

  .vs-card--content {
    font-size: 1em !important;
  }

  .announcement {
    &-details {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr;
    }

    &-image {
      border-radius: 5px;
      float: left;
      height: 180px;
      margin: 0 10px 3px 0;
      object-fit: cover;
      width: 100%;
    }
  }

  .agent-image {
    border-radius: 5px;
    flex-shrink: 0;
    height: 300px;
    object-fit: cover;
    object-position: top;
    width: 100%;
  }

  .agent-details {
    display: grid;
    grid-template-columns: 1fr;
  }

  .collapse {
    border: 1px solid #999 !important;
    border-radius: 10px;
  }
  .announcement {
    background: rgba(var(--vs-warning), 0.1);
    background: linear-gradient(158deg, rgba(var(--vs-warning), 0.1) 10%, rgba(255,255,255,1) 66%);
  }

  .seller {
    background: rgba(var(--vs-warning), 0.1);
  }
  .swiper-slide.details {
    background: url("../../../assets/images/Slider/details.png");
    background-size: cover;
  }
}

@include sm-min {
  .admin-appointments-id {
    .announcement-details {
      grid-template-columns: 1fr 1fr;
    }
    .agent-image {
      height: 110px;
      width: 110px;
    }
    .agent-details {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include md-min {
  .admin-appointments-id {
    .associated-info {
      grid-template-columns: 400px 1fr;
    }
    .agent-details {
      grid-template-columns: 1fr;
    }
  }
}

@include lg-min {
  .admin-appointments-id {
    .announcement-details {
      grid-template-columns: 220px 1fr;
    }
    .associated-info {
      grid-template-columns: 500px 1fr;
    }
    .agent-details {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
